import hagaoCover from '../../img/hagao_cover.webp'
import xodzeCover from '../../img/xodze_cover.svg'

import Navbar from './navbar';

const Cover  = () => {
    return (
       <div className="cover">
        {window.innerWidth > 800 &&
            <Navbar />
        }
            <img src={xodzeCover} alt="" />
            <img src={hagaoCover} alt="" className='cover__hagao'/>
       </div>
    );
}

export default Cover