import Button from '../atoms/Button.jsx'
import FooterLogo from '../../img/footer-logo.svg'
import DiscordBtn from '../../img/discord.svg'
import Telegram from '../../img/telegram.svg'
import Behance from '../../img/behance.svg' 



const Footer  = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer__support">
                    <p className="footer__title header-2">Свяжитесь с нами</p>
                    <p className="footer__description description-1">Если у вас есть вопросы по продуктам, желание заколлабиться или вы просто хотите пообщаться — пишите нам</p>
                    <Button 
                        type={'accent'} 
                        text={<a href="mailto:support@xodze.com">support@xodze.com</a>} 
                        icon={'mail-black'}
                    />
                </div>
                <p className="footer__copyright">
                    Xodze. 2023
                </p>
                <div className="footer__contact">
                    <img className='footer__logo' src={FooterLogo} alt="" />
                    <a href='https://discord.gg/c5CWpX2kmq'><img src={DiscordBtn} alt="" className='footer__contact-icon'/></a>
                    <a href='https://t.me/xodzesquad'><img src={Telegram} alt="" className='footer__contact-icon'/></a>
                    <a href='https://www.behance.net/designxodze'><img src={Behance} alt="" 
                    className='footer__contact-icon'/></a>
                </div>
            </div>
        </div>
    );
}

export default Footer