import NewsCard from './news_card'

import subscription from '../../img/subscription.webp'
import xodzeBehance from '../../img/xodze-behance.webp'
import clubs from '../../img/interest-clubs.webp'
import newHagao from '../../img/new-hagao.webp'
import testDirector from '../../img/test-director.webp'
import directorNewVersion from '../../img/director-new-version.webp'
import serverNavigation from '../../img/discord-navigation.webp'
import forSponsors from '../../img/for-sponsors.webp'


const NewsCardGroup  = () => {
    return (
        <div>
            <p className='header header-1'>Новости <br/> продуктов</p>
            <div className="newsCardGroup">
                <NewsCard 
                    imgName={xodzeBehance}  
                    titleText={'Выложили брендбук на Behance'}
                    descriptionText={'Мы сделали подходящее оформление и выложили брендбук на беханс. Об основной версии рассказывали ранее в этом посте.'} 
                />
                <NewsCard 
                    imgName={newHagao} 
                />
                <NewsCard 
                    imgName={clubs} 
                    titleText={'Система клубов на Ходзе Сквад'} 
                    descriptionText={'Обновили подход к организации тематических сообществ. Теперь каждый может стать организатором кружка по интересам и получить инструменты для его развития.'} 
                />
                <NewsCard 
                    imgName={testDirector} 
                    titleText={'Старт закрытого тестирования'} 
                    descriptionText={'Создание основных функций завершено, и теперь мы приступаем к выявлению недостатков текущей сборки'} 
                />
                <NewsCard 
                    imgName={directorNewVersion} 
                    titleText={'Запуск Ходзе Директора'} 
                    descriptionText={'Вчера вечером обновили код нашего бота для администраторов дискорд-сообществ. Теперь Директор обладает всем заявленным функционалом.'} 
                />
                <NewsCard 
                    imgName={serverNavigation} 
                    titleText={'Путеводитель по серверу'} 
                    descriptionText={'Теперь новые участники познакомятся с основными каналами сервера уже в первые минуты после выбора ролей, а сами каналы оптимизированы'} 
                />
                <NewsCard 
                    imgName={subscription} 
                    titleText={'Стоимость Ходзе Плюс снижена до 149 рублей'} 
                    descriptionText={'Скидка 40% на дополнительные возможности в сообществе на месяц уже доступна в нашем магазине'} 
                />
                <NewsCard 
                    imgName={forSponsors} 
                    titleText={'Обновление спонсорских подписок'} 
                    descriptionText={'Мы ввели подписку Xodze Plus, которая является единой спонсорской подпиской. Получить ее можно в Xodze Store, а также на время действия двух бустов сервера. За одиночный буст доступно ограниченное количество бонусов.'} 
                />
            </div>
        </div>
       
    );
}

export default NewsCardGroup