import Button from "../atoms/Button";
import Logo from '../../img/logo.svg';
import { useState } from "react";
import {ReactComponent as Icon} from '../../img/menu-burger.svg'

const Navbar  = () => {
const [isOpen , setOpen] = useState(false);
    return (
        <div className={isOpen ? 'navbar active' : 'navbar'}>
            <img src={Logo} alt="" />
                <ul className='navbar__menu'>
                    <li><a href="#">Director</a></li>
                    <li><a href="#">Store</a></li>
                    <li><a href="#">Squad</a></li>
                    <li><a href="#">О проекте</a></li>
                </ul> 
                <div className="nav__join">
                    <Button
                        type={'neutral'} 
                        text={<a href="https://discord.gg/c5CWpX2kmq">Присоединиться</a>} 
                    />
                </div>
                <button className="menu-btn" onClick={() => setOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                </button>
        </div>
        );
}

export default Navbar