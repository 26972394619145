import Button from "../atoms/Button";

const NewsCard  = ({imgName, titleText, descriptionText}) => {
    return (
       <div className="news">
            <img className="news__img" src={imgName} alt="" />
            <div className="news__text-container">
                    <p className="header-3">{titleText}</p>
                    <p className="description-2">{descriptionText}</p>                
            </div>
            <Button 
                    type={'neutral'} 
                    text={<a href='https://t.me/xodzesquad'>Узнать больше</a>} 
                    icon={'arrow-up-white'}
                /> 
       </div>
    );
}

export default NewsCard