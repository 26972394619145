import './App.css'
import './reset.css'
import Navbar from './components/organisms/navbar'
import Cover from './components/organisms/cover'
import Invite from './components/organisms/invite'
import CardsGroup from './components/organisms/CardsGroup'
import NewsCardGroup from './components/organisms/newsCard-group'
import Footer from './components/organisms/footer'

import leadGif1 from './img/xodze-background.webm'


function App() {
  return (
    <div>
        <div className="app">
          {window.innerWidth <= 800 &&
            <Navbar />
          }
          <Cover />
          <Invite />
          <CardsGroup />
          <NewsCardGroup />
          <Footer />
          <video autoPlay playsInline pip='false'  muted loop>
             <source src={leadGif1} type="video/webm"/>
            </video>
        </div>
    </div>
  );
}

export default App;
