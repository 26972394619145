import ButtonGroup from '../molecules/button_group.jsx'
import Button from '../atoms/Button.jsx';

const BIGcard  = ({size, firstButtonText, secondButtonText, titleText, descriptionText, imgName}) => {
    return (
       <div className={"card " + size}>
            <div className="text-container">
                <div>
                    {size == 'big' ?
                    <p className="header-1">{titleText}</p> :
                    <p className="header-2">{titleText}</p>  
                    }
                    <p className="description-1">{descriptionText}</p>
                </div>
                <div className="card-button-group">
                    {size == 'big' ?
                    <ButtonGroup 
                        firstButtonText={firstButtonText} 
                        secondButtonText={secondButtonText}
                    /> :
                    <Button 
                        type={'neutral'}
                        text={firstButtonText}
                        icon={'arrow-up-white'}
                    />   
                    }
                </div>
            </div>
            <div className="bigcard__img">
                <img src={imgName} alt="" />
            </div>
       </div>
    );
}

export default BIGcard