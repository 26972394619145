import Card from './Card.jsx'
import directorImg from '../../img/director.webp'
import storeImg from '../../img/store.webp'
import hagaoImg from '../../img/hagao.webp'
import squadImg from '../../img/squad.webp'

const CardsGroup  = () => {
    return (
       <div className="card-group">
            <Card
                size={'big'}
                titleText={'Director'}
                descriptionText={'Многофункциональный бот для администрирования серверов Discord с поддержкой экосистемности.'}
                firstButtonText={<a href='https://t.me/xodzesquad'>Попробовать</a>} 
                secondButtonText={<a href='https://t.me/xodzesquad'>Узнать больше</a>} 
                imgName={directorImg}     
            />
            <Card
                size={'big'}
                titleText={'Store'}
                descriptionText={'Наш онлайн-магазин, где вы можете приобрести Discord Nitro и другие цифровые товары по самым низким ценам.'}
                firstButtonText={<a href='https://t.me/xodzesquad'>Попробовать</a>}
                secondButtonText={<a href='https://t.me/xodzesquad'>FunPay</a>} 
                imgName={storeImg}     
            />
            <Card
                size={'small'}
                titleText={'Hagao'}
                descriptionText={'Наш лучший друг и самый умный на свете ИИ-белколис'}
                firstButtonText={<a href='https://t.me/xodzesquad'>Попробовать</a>}
                imgName={hagaoImg}     
            />
            <Card
                size={'small'}
                titleText={'Squad'}
                descriptionText={'Официальный сервер нашей команды'}
                firstButtonText={<a href='https://t.me/xodzesquad'>Попробовать</a>}
                imgName={squadImg}     
            />

       </div>
    );
}

export default CardsGroup