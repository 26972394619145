import Button from "../atoms/Button";

const Invite  = () => {
    return (
       <div className="invite">
            <div className="container">
                <h1 className="invite__title header-1">Xodze — команда,  реализующая безумные идеи</h1>
                <p className="description-1">Присоединяйтесь к нашему серверу в Discord, чтобы быть в курсе всех новостей</p>
                <Button
                    type={'neutral'}
                    text={<a href="https://discord.gg/c5CWpX2kmq">Присоединиться</a>}
                />
            </div>
       </div>
    );
}

export default Invite