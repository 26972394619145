import Button from "../atoms/Button";

const ButtonGroup  = ({icon, firstButtonText, secondButtonText, primaryButtonWithIcon}) => {
    return (
        <div className="btn__group">
            <Button type={'accent'} style={''} text={firstButtonText}/>
            <Button type={'neutral'} style={''} text={secondButtonText} icon={'arrow-up-white'}/>
        </div>
    );
}

export default ButtonGroup